export const environment = {
  production: false,
  authentication: {
    scope: '',
    clientId: '6d96f63f-9ab1-40b9-8e94-0078b4016a85' //Chave Pública da credencial configurada no aplicacoes.studio.betha.cloud
  },
  api: {
    url: 'https://www.easydoc.com.br/betha-api/api', //https://localhost:7201/api
    betha: 'https://plataforma-autorizacoes.betha.cloud'
  }
};