import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AutenticacaoContextService } from "src/app/authentication/ui-angular-autenticacao";
import { ParametroResource } from "src/app/models/parametro/parametro-resource.model";
import { BethaService } from "src/app/services/betha/betha.service";
import { ParametroService } from "src/app/services/parametro/parametro.service";


@Component({
    selector: 'app-painel',
    templateUrl: './painel.component.html',
    styleUrls: ['./painel.component.scss']
})
export class PainelComponent implements OnInit, OnDestroy {
    current_url: string = '';
    private parametro: ParametroResource;
    private appId: string;
    //private sheet: string;
    private entidadeId: string;
    private entidadeNome: string;
    private usuarioLoginEmail: string;
    private usuarioNome: string;
    
    private parametroSubscription: Subscription;
    private userInfoSubscription: Subscription;
    private routerSubscription: Subscription;

    constructor(private route: ActivatedRoute,
        private parametroService: ParametroService,
        private autenticacaoContextService: AutenticacaoContextService,
        private bethaService: BethaService,
        private router: Router) {
            //Fica verificando se houve mudança na rota da página, caso positivo recarrega o iframe com os novos parâmetros
            this.routerSubscription = router.events.subscribe((val) => {
                if(val instanceof NavigationEnd)
                    if(val.id != 1)
                        this.carregarIframe();
            })
         }

    ngOnInit(): void {
        this.carregarIframe();
    }

    ngOnDestroy(): void {
        this.parametroSubscription.unsubscribe();
        this.userInfoSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
    }

    updateSrc(url: string) {
        this.current_url = url;
    }

    carregarIframe() {
        this.appId = this.route.snapshot.paramMap.get('appid');
        //this.sheet = this.route.snapshot.paramMap.get('sheet');
        
        this.userInfoSubscription = this.autenticacaoContextService.getInformacaoUsuario().subscribe(usuario => {
            this.usuarioLoginEmail = usuario.emails.primario;
            this.usuarioNome = usuario.nome;
            this.entidadeId = sessionStorage.getItem('entidade_id');

            var param = {
                loginEmail: this.usuarioLoginEmail,
                nomeUsuario: this.usuarioNome,
                entidadeId: this.entidadeId
            };
            this.obterParametroEntidade(param);
        });
    }

    obterParametroEntidade(param: any) {
        this.parametroSubscription = this.parametroService.getParametroEntidade(param).subscribe((param) => {
            if (param.succeeded) {
                this.parametro = param.data
                this.logar();
            }
        });
    }

    logar() {
        var url = this.parametro.urlTenant;
        var jwt = this.parametro.jwt;
        var webIntegrationID = this.parametro.webIntegrationId;

        var raw = "";

        var myHeaders = new Headers();
        myHeaders.append("qlik-web-integration-id", webIntegrationID);
        myHeaders.append("content-type", "application/json");
        myHeaders.append("Authorization", "Bearer " + jwt);

        fetch(`https://${url}/login/jwt-session?qlik-web-integration-id=${webIntegrationID}`, {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            credentials: "include",
            mode: "cors"
        })
            .then(response => response.text())
            .then(result => {
                console.log(result);
                //this.current_url = `https://${url}/single/?appid=${this.appId}&sheet=${this.sheet}&theme=sense&opt=ctxmenu`; //Utilizando o Single
                //this.current_url = `https://${url}/sense/app/${this.appId}/sheet/${this.sheet}/theme/sense/state/analysis`; //Definindo qual Sheet deve ser aberto
                this.current_url = `https://${url}/sense/app/${this.appId}/state/analysis/theme/card`; //O Sheet padrão deve ser definido na configuração do painel
            })
            .catch(error => console.log('error', error));
    }
}