import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AutenticacaoRequestsInterceptor } from './autenticacao-requests.interceptor';


@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AutenticacaoRequestsInterceptor,
        multi: true
    }]
})
export class AutenticacaoModule { }
