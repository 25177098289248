import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PainelComponent } from './pages/painel/painel.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'painel/:appid', component: PainelComponent },
  { path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], //[RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })]
  exports: [RouterModule]
})
export class AppRoutingModule { }
