import { OAuthConfig, OpenIDProvider } from '@betha-plataforma/oauth';

const OAUTH_URL: string = window['___bth'].envs.suite.oauth.v1.host; // tslint:disable-line
const SERVICE_LOGIN_URL: string = window['___bth'].envs.suite['service-login'].v1.host; // tslint:disable-line
const USERS_URL: string = window['___bth'].envs.suite.usuarios.v1.host; // tslint:disable-line

type OAuthConfigAutoProvider = Pick<OAuthConfig, Exclude<keyof OAuthConfig, 'provider'>>;

export function bethaOIDCProviderFactory(config: OAuthConfigAutoProvider): OAuthConfig {
  const provider: OpenIDProvider = {
    authorization_endpoint: `${OAUTH_URL}/authorize`,
    check_session_iframe: `${SERVICE_LOGIN_URL}/openidsso.jsp`,
    end_session_endpoint: `${SERVICE_LOGIN_URL}/logout?continue=${OAUTH_URL}/authorize?client_id=${config.clientId}%26response_type=token%26redirect_uri=${config.redirectUri}%26scope=${config.scope}`, //tslint:disable-line
    introspect_endpoint: `${OAUTH_URL}/tokeninfo`,
    token_endpoint: `${OAUTH_URL}/token`,
    userinfo_endpoint: `${USERS_URL}/api/usuarios/@me`
  };
  return { ...config, provider };
}
