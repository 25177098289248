import { Injectable, InjectionToken, Inject } from '@angular/core';
import { OAuthApplication } from '@betha-plataforma/oauth';
import { Usuario } from './usuario';
import { of, Observable } from 'rxjs';

export const OAUTH_APPLICATION: InjectionToken<OAuthApplication> = new InjectionToken('OauthApplication');

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoContextService {

  constructor(@Inject(OAUTH_APPLICATION) private oauthApplication: OAuthApplication) {
  }

  logout() {
    this.oauthApplication.logout();
  }

  refreshToken(): Promise<any> {
    return this.oauthApplication.silentRefresh();
  }

  readonly getInformacaoUsuario = (): Observable<Usuario> => {
    const user: any = this.oauthApplication.getUser();
    return of({
      celular: user.cellPhone,
      dataNascimento: user.birthDay,
      emails: {
        primario: user.email,
        secundario: user.secondaryEmail
      },
      foto: user.photo.split('?')[0].concat('?access_token=').concat(this.getAccessToken()),
      id: user.id,
      nome: user.name
    });
  }

  readonly getAccessToken = (): string => {
    if (this.oauthApplication.hasActiveSession()) {
      const token = this.oauthApplication.getSession().accessToken;
      return token ? token.access_token : undefined;
    }
    return undefined;
  }

}
