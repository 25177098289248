import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, Subject, throwError, from } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AutenticacaoContextService } from './autenticacao-context.service';

@Injectable({ providedIn: 'root' })
export class AutenticacaoRequestsInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private tokenRefreshedSource = new Subject();
  private tokenRefreshedSource$ = this.tokenRefreshedSource.asObservable();

  constructor(private authenticationContextService: AutenticacaoContextService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(this.addAuthRequest(req)).pipe(catchError((err: HttpErrorResponse) => {
      if ([401, 410].includes(err.status)) {
        return this.refreshToken(req, next)
          .pipe(
            switchMap(() => {
              return next.handle(this.addAuthRequest(req));
            }),
            catchError(err1 => {
              this.authenticationContextService.logout();
              return throwError(err1);
            })
          );
      }
      return throwError(err);
    }));

  }

  private refreshToken(req: HttpRequest<any>, next: HttpHandler) {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshedSource$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    }
    this.refreshTokenInProgress = true;
    return from(this.authenticationContextService.refreshToken()).pipe(
      tap(() => {
        this.refreshTokenInProgress = false;
        this.tokenRefreshedSource.next();
      }),
      catchError((err) => {
        this.refreshTokenInProgress = false;
        this.authenticationContextService.logout();
        return throwError(err);
      })
    );
  }

  private addAuthRequest(req: HttpRequest<any>) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authenticationContextService.getAccessToken()}`
      },
    });
  }

}
