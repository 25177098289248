import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MenuResource } from "src/app/models/menu/menu-resource.model";
import { ResponseApi } from "src/app/models/response-api/response-api-resource.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private URL_API = `${environment.api.url}/Menu`;

    constructor(private httpClient: HttpClient) { }

    getMenu(params: any) : Observable<ResponseApi<MenuResource[]>> {
        return this.httpClient.post<ResponseApi<MenuResource[]>>(`${this.URL_API}/ObterMenuUsuario`, params).pipe();
    }
}