import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Authorization, AuthorizationConfig, OpcaoMenu, OpcaoMenuSelecionadaEvent } from '@betha-plataforma/estrutura-componentes';
import { AutenticacaoContextService, Usuario } from './authentication/ui-angular-autenticacao';
import { oAuthApplication } from './authentication/oauth-application';
import { Subscription } from 'rxjs';
import { MenuService } from './services/menu/menu.service';
import { MenuResource } from "src/app/models/menu/menu-resource.model";
import { BethaService } from './services/betha/betha.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('app', { static: true }) appElement: ElementRef<HTMLBthAppElement>;
  @ViewChild('marcaProduto', { static: true }) marcaProdutoElement: ElementRef<HTMLBthMarcaProdutoElement>;
  @ViewChild('contaUsuario', { static: true }) contaUsuarioElement: ElementRef<HTMLBthContaUsuarioElement>;

  private opcoesMenu: Array<OpcaoMenu> = [
    { id: 'menu-home', descricao: 'Home', icone: 'home', rota: '/', possuiPermissao: true }
  ];
  private menuModel: Array<MenuResource> = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    private autenticacaoContextService: AutenticacaoContextService,
    private menuService: MenuService,
    private bethaService: BethaService
  ) { }

  private userInfoSubscription: Subscription;
  private licencaInfoSubscription: Subscription;
  private menuSubscription: Subscription;

  ngOnInit(): void {
    //Deixando o menu escondido por padrão
    localStorage.removeItem('bth-menu:state');
    localStorage.setItem('bth-menu:state', '{"aberto":false,"flutuando":true}');
    
    this.configurarMarcaProduto();
    this.userInfoSubscription = this.autenticacaoContextService.getInformacaoUsuario().subscribe(usuario => {
      console.log(usuario);
      this.configurarContaUsuario(usuario);
      this.configurarMenu(usuario);
    });
  }

  ngOnDestroy(): void {
    this.userInfoSubscription.unsubscribe();
    this.licencaInfoSubscription.unsubscribe();
    this.menuSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    //Deixando o header da página com z-index = 2, dessa forma consigo esconder o cabeçalho do Qlik
    const shadowRoot: DocumentFragment = this.appElement.nativeElement.shadowRoot;
    var css = document.createElement('style');
    shadowRoot.prepend(css);
    css.sheet.insertRule(".menu-horizontal { z-index: 2 !important; }", 0);
  }

  private configurarApp() {
    this.appElement.nativeElement.menuVertical = true;
    this.appElement.nativeElement.opcoes = this.opcoesMenu;

    this.appElement.nativeElement.addEventListener('opcaoMenuSelecionada', (event: CustomEvent<OpcaoMenuSelecionadaEvent>) => {
      this.ngZone.run(() => {
        this.router.navigate([event.detail.rota]);
      })
    });

    this.setMenuAtivoPorRota(this.router.url);

    this.router.events.subscribe(routeEvent => {
      if (routeEvent instanceof NavigationEnd) {
        const route: NavigationEnd = routeEvent;
        this.setMenuAtivoPorRota(route.url);
      }
    });
  }

  private configurarMenu(usuario: Usuario) {
    this.licencaInfoSubscription = this.bethaService.getInfoLicenca(this.autenticacaoContextService.getAccessToken()).subscribe((infoLicenca) => {
      var sessionEntidadeId = sessionStorage.getItem('entidade_id');
      if (window.location.hash.indexOf("entidade") > -1 || sessionEntidadeId != null) {
        
        if (window.location.hash.indexOf("entidade") > -1) {
          var contexto = window.location.hash.split('/')[window.location.hash.split('/').length - 1];
          var result = infoLicenca.filter((obj) => {
            return obj.context == contexto;
          });
          
          if (result) {
            sessionEntidadeId = result[0].values.entity;
            sessionStorage.setItem('entidade_id', result[0].values.entity);
          }
        }

        if (sessionEntidadeId) {
          this.menuSubscription = this.menuService.getMenu({ login: usuario.emails.primario, usuarioNome: usuario.nome, entidadeId: sessionEntidadeId }).subscribe((menu) => {
            this.menuModel = menu.data;
            this.menuModel.forEach(element => {
              var opcaoMenuPai: OpcaoMenu = { id: "", descricao: "", icone: "", possuiPermissao: false, rota: "", submenus: [] };
              opcaoMenuPai.id = element.id;
              opcaoMenuPai.descricao = element.titulo;
              opcaoMenuPai.rota = null;
              opcaoMenuPai.icone = 'circle-small';
              opcaoMenuPai.possuiPermissao = true;
  
              if (element.filhos) {
                element.filhos.forEach(element_filho => {
                  var opcaoMenuFilho: OpcaoMenu = { id: "", descricao: "", icone: "", possuiPermissao: false, rota: "", submenus: [] };
                  opcaoMenuFilho.id = element_filho.id;
                  opcaoMenuFilho.descricao = element_filho.titulo;
                  opcaoMenuFilho.rota = `/painel/${element_filho.appId}`;
                  opcaoMenuFilho.icone = null;
                  opcaoMenuFilho.possuiPermissao = true;
  
                  opcaoMenuPai.submenus.push(opcaoMenuFilho);
                });
              }
  
              this.opcoesMenu.push(opcaoMenuPai);
            });
            this.configurarApp();
          });
        }
      } 
      else if (sessionEntidadeId === null) {
        //Caso na URL não contenha a palavra 'entidade' e na sessionStorage não esteja definido o valor para 'entidade_id', redireciona para fazer o login pelo betha.cloud
        window.location.href = "https://betha.cloud/";
      }
    });
  }

  private setMenuAtivoPorRota(rota: string) {
    this.opcoesMenu.forEach(opcaoMenu => {
      if (opcaoMenu.rota === rota) {
        this.appElement.nativeElement.setMenuAtivo(opcaoMenu.id);
      }

      if (opcaoMenu.submenus) {
        opcaoMenu.submenus.forEach(submenu => {
          if (rota.includes(submenu.rota)) {
            this.appElement.nativeElement.setMenuAtivo(submenu.id);
          }
        });
      }
    })
  }

  private configurarMarcaProduto() {
    this.marcaProdutoElement.nativeElement.authorization = this.getAuthConfig();
    this.marcaProdutoElement.nativeElement.exibirProdutos = false;
    this.marcaProdutoElement.nativeElement.produto = "BI Betha";
    console.log(this.marcaProdutoElement.nativeElement.userAccountsApi);
  }

  private async configurarContaUsuario(usuario: Usuario) {
    await customElements.whenDefined('bth-conta-usuario');

    this.contaUsuarioElement.nativeElement.usuario = usuario.id;
    this.contaUsuarioElement.nativeElement.nome = usuario.nome;
    this.contaUsuarioElement.nativeElement.fotoUrl = usuario.foto;

    this.contaUsuarioElement.nativeElement.addEventListener('logout', () => {
      this.autenticacaoContextService.logout();
    });
  }

  private getAuthConfig = (): AuthorizationConfig => ({
    getAuthorization: this.getAuthorization,
    handleUnauthorizedAccess: this.handleUnauthorizedAccess
  })

  private getAuthorization = (): Authorization => {
    return {
      accessToken: this.autenticacaoContextService.getAccessToken()
    };
  }

  private handleUnauthorizedAccess = async (): Promise<void> => {
    if (oAuthApplication.hasActiveSession()) {
      return Promise.resolve();
    }

    await oAuthApplication.silentRefresh();

    return Promise.resolve();
  }
}
