import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {oAuthApplication} from './authentication/oauth-application';
import {AutenticacaoModule, OAUTH_APPLICATION} from './authentication/ui-angular-autenticacao';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { SafeUrlModule } from './pipes/safe-url/safe-url.module';
import { HomeComponent } from './pages/home/home.component';
import { PainelComponent } from './pages/painel/painel.component';
import { MenuService } from './services/menu/menu.service';
import { ParametroService } from './services/parametro/parametro.service';
import { BethaService } from './services/betha/betha.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PainelComponent
  ],
  imports: [
    HttpClientModule,
    AutenticacaoModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    SafeUrlModule
  ],
  providers: [
    { provide: OAUTH_APPLICATION, useValue: oAuthApplication },
    CookieService,
    MenuService,
    ParametroService,
    BethaService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
