import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LicencaBethaResource } from "src/app/models/betha/licenca-betha-resource.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class BethaService {
    private URL_API = `${environment.api.betha}/user-accounts/v0.1/api/suite/users/@me/access`;

    constructor(private httpClient: HttpClient) { }

    getInfoLicenca(accessToken: string) : Observable<LicencaBethaResource[]> {
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${accessToken}`
          })
        return this.httpClient.get<LicencaBethaResource[]>(`${this.URL_API}`, { headers: headers }).pipe();
    }
}