import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ParametroResource } from "src/app/models/parametro/parametro-resource.model";
import { ResponseApi } from "src/app/models/response-api/response-api-resource.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ParametroService {
    private URL_API = `${environment.api.url}/Parametro`;

    constructor(private httpClient: HttpClient) { }

    getParametroEntidade(param: any) : Observable<ResponseApi<ParametroResource>> {
        return this.httpClient.post<ResponseApi<ParametroResource>>(`${this.URL_API}/ObterParametrosIframeFront`, param).pipe();
    }
}